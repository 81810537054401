import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Dokumen {
  id: number;
  file: string;
  judul: string;
  dokumen: number;
  kategori: number;
  tipe: number;
}

export interface DokumenInfo {
  objectDokumen: Dokumen;
  listArrayDokumen: Array<Dokumen>;
}

@Module
export default class DokumenModule extends VuexModule implements DokumenInfo {
  objectDokumen = {} as Dokumen;
  listArrayDokumen = [];

  get resGetDokumen() {
    return this.listArrayDokumen;
  }

  // get formDokumen() {
  //   return this.objectDokumen;
  // }

  @Mutation
  [Mutations.listDokumen](resData) {
    console.log(resData);
    this.listArrayDokumen = resData.isidata;
  }

  @Mutation
  [Mutations.setFormDokumen](resData) {
    console.log(resData);
    this.objectDokumen = resData;
  }

  // @Mutation
  // [Mutations.setIdDokumen](resData) {
  //   this.objectDokumen.id = resData.id;
  // }

  @Action
  [Actions.postDokumen](rowData) {
    ApiService.setHeader();
    const formdata = new FormData();
    formdata.append("judul", rowData.judul);
    formdata.append("dokumen", rowData.dokumen);
    formdata.append("tipe", rowData.tipe);
    formdata.append("kategori", rowData.kategori);
    formdata.append("file", rowData.file);
    return ApiService.postFormData("dokumen/", rowData)
      .then(({ data }) => {
        console.log(data);
        this.context.commit(Mutations.setIdDokumen, data);
      })
      .catch(({ response }) => {
        // this.context.commit(Mutations.SET_AUTH, []);
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.putDokumen](rowData) {
    ApiService.setHeader();
    const formdata = new FormData();
    formdata.append("judul", rowData.judul);
    formdata.append("dokumen", rowData.dokumen);
    formdata.append("tipe", rowData.tipe);
    formdata.append("kategori", rowData.kategori);
    formdata.append("file", rowData.file);
    return ApiService.putFormData("dokumen/" + rowData.id, rowData)
      .then(({ data }) => {
        console.log(data);
        this.context.commit(Mutations.statusResApi, true);
      })
      .catch(({ response }) => {
        // this.context.commit(Mutations.SET_AUTH, []);
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.deleteDokumen](rowData) {
    ApiService.setHeader();
    return ApiService.delete("dokumen/" + rowData.id)
      .then(({ data }) => {
        console.log(data);
        this.context.commit(Mutations.statusResApi, true);
      })
      .catch(({ response }) => {
        // this.context.commit(Mutations.SET_AUTH, []);
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.getDokumen]() {
    return ApiService.get("dokumen/")
      .then(({ data }) => {
        // this.context.commit(Mutations.statusResApi, true);
        this.context.commit(Mutations.listDokumen, data);
      })
      .catch(({ response }) => {
        // this.context.commit(Mutations.SET_AUTH, []);
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.getDokumenById](id) {
    return ApiService.get("dokumen/byid/" + id)
      .then(({ data }) => {
        // this.context.commit(Mutations.statusResApi, true);
        this.context.commit(Mutations.listDokumen, data);
      })
      .catch(({ response }) => {
        // this.context.commit(Mutations.SET_AUTH, []);
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.downloadDokumen](name) {
    return ApiService.get("dokumen/download/" + name)
      .then(({ data }) => {
        // this.context.commit(Mutations.statusResApi, true);
        this.context.commit(Mutations.listDokumen, data);
      })
      .catch(({ response }) => {
        // this.context.commit(Mutations.SET_AUTH, []);
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
}
