import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Menu {
  id: number;
  menu: string;
  urutan: number;
  induk: number;
  url: string;
  status: number;
}

export interface MenuInfo {
  objectMenu: Menu;
  listArrayMenu: Array<Menu>;
}

@Module
export default class MenuModule extends VuexModule implements MenuInfo {
  objectMenu = {} as Menu;
  listArrayMenu = [];

  get resGetMenu() {
    return this.listArrayMenu;
  }

  get formMenu() {
    return this.objectMenu;
  }

  @Mutation
  [Mutations.listMenu](resData) {
    console.log(resData);
    this.listArrayMenu = resData;
  }

  @Mutation
  [Mutations.setFormMenu](resData) {
    console.log(resData);
    this.objectMenu = resData;
  }

  @Action
  [Actions.postMenu](rowData) {
    ApiService.setHeader();
    return ApiService.post("menu/", rowData)
      .then(({ data }) => {
        console.log(data);
        this.context.commit(Mutations.statusResApi, true);
      })
      .catch(({ response }) => {
        // this.context.commit(Mutations.SET_AUTH, []);
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.putMenu](rowData) {
    ApiService.setHeader();
    return ApiService.put("menu/" + rowData.id, rowData)
      .then(({ data }) => {
        console.log(data);
        this.context.commit(Mutations.statusResApi, true);
      })
      .catch(({ response }) => {
        // this.context.commit(Mutations.SET_AUTH, []);
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.deleteMenu](rowData) {
    ApiService.setHeader();
    return ApiService.delete("menu/" + rowData.id)
      .then(({ data }) => {
        console.log(data);
        this.context.commit(Mutations.statusResApi, true);
      })
      .catch(({ response }) => {
        // this.context.commit(Mutations.SET_AUTH, []);
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.getMenu]() {
    return ApiService.get("menu/")
      .then(({ data }) => {
        // this.context.commit(Mutations.statusResApi, true);
        this.context.commit(Mutations.listMenu, data);
      })
      .catch(({ response }) => {
        // this.context.commit(Mutations.SET_AUTH, []);
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
}
