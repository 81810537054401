const Username = "username" as string;
const Role = "role" as string;
const Email = "email" as string;
const Id = "id" as string;
/**
 * @description get token form localStorage
 */
export const getUsername = (): string | null => {
  return window.localStorage.getItem(Username);
};

/**
 * @description save token into localStorage
 * @param username: string
 */
export const saveUsername = (username: string): void => {
  window.localStorage.setItem(Username, username);
};

/**
 * @description remove token form localStorage
 */
export const destroyUsername = (): void => {
  window.localStorage.removeItem(Username);
};

/**
 * @description get token form localStorage
 */
export const getEmail = (): string | null => {
  return window.localStorage.getItem(Email);
};

/**
 * @description save token into localStorage
 * @param email: string
 */
export const saveEmail = (email: string): void => {
  window.localStorage.setItem(Email, email);
};

/**
 * @description remove token form localStorage
 */
export const destroyEmail = (): void => {
  window.localStorage.removeItem(Email);
};

/**
 * @description get token form localStorage
 */
export const getId = (): string | null => {
  return window.localStorage.getItem(Id);
};

/**
 * @description save token into localStorage
 * @param id: string
 */
export const saveId = (id: string): void => {
  window.localStorage.setItem(Id, id);
};

/**
 * @description remove token form localStorage
 */
export const destroyId = (): void => {
  window.localStorage.removeItem(Id);
};

/**
 * @description get token form localStorage
 */
export const getRole = (): string | null => {
  return window.localStorage.getItem(Role);
};

/**
 * @description save token into localStorage
 * @param id: string
 */
export const saveRole = (role: string): void => {
  window.localStorage.setItem(Role, role);
};

/**
 * @description remove token form localStorage
 */
export const destroyRole = (): void => {
  window.localStorage.removeItem(Role);
};

export default {
  getUsername,
  saveUsername,
  destroyUsername,
  getEmail,
  saveEmail,
  destroyEmail,
  getId,
  saveId,
  destroyId,
  getRole,
  saveRole,
  destroyRole,
};
