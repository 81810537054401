import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Layanan {
  id: number;
  judul: string;
  gambar: number;
  isi: string;
  Status: number;
  displayImage: string;
}

export interface LayananInfo {
  layanan: Layanan;
  listArrayLayanan: Array<Layanan>;
}

@Module
export default class LayananModule extends VuexModule implements LayananInfo {
  layanan = {} as Layanan;
  listArrayLayanan = [];

  get resGetLayanan() {
    return this.listArrayLayanan;
  }

  get formLayanan() {
    return this.layanan;
  }

  @Mutation
  [Mutations.listLayanan](resData) {
    console.log(resData);
    this.listArrayLayanan = resData;
  }

  @Mutation
  [Mutations.setFormLayanan](resData) {
    console.log(resData);
    this.layanan = resData;
  }

  @Action
  [Actions.postLayanan](rowData) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      return ApiService.post("layanan", rowData)
        .then(({ data }) => {
          console.log(data);
          this.context.commit(Mutations.statusResApi, true);
          resolve(data);
        })
        .catch(({ response }) => {
          // this.context.commit(Mutations.SET_AUTH, []);
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  }

  @Action
  [Actions.putLayanan](rowData) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      return ApiService.put("layanan/" + rowData.id, rowData)
        .then(({ data }) => {
          console.log(data);
          this.context.commit(Mutations.statusResApi, true);
          resolve(data);
        })
        .catch(({ response }) => {
          // this.context.commit(Mutations.SET_AUTH, []);
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  }

  @Action
  [Actions.deleteLayanan](rowData) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      return ApiService.delete("layanan/" + rowData.id)
        .then(({ data }) => {
          console.log(data);
          this.context.commit(Mutations.statusResApi, true);
          resolve(data);
        })
        .catch(({ response }) => {
          // this.context.commit(Mutations.SET_AUTH, []);
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  }

  @Action
  [Actions.getLayanan]() {
    return new Promise((resolve, reject) => {
      return ApiService.get("layanan")
        .then(({ data }) => {
          // this.context.commit(Mutations.statusResApi, true);
          this.context.commit(Mutations.listLayanan, data);
          resolve(data);
        })
        .catch(({ response }) => {
          // this.context.commit(Mutations.SET_AUTH, []);
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  }
}
