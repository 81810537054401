import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Portofolio {
  id: number;
  judul: string;
  gambar: number;
  desc: string;
  displayImage: string;
  scope: string;
  client: string;
  duration: number;
}

export interface PortofolioInfo {
  portofolio: Portofolio;
  listArrayPortofolio: Array<Portofolio>;
}

@Module
export default class PortofolioModule
  extends VuexModule
  implements PortofolioInfo
{
  portofolio = {} as Portofolio;
  listArrayPortofolio = [];

  get resGetPortofolio() {
    return this.listArrayPortofolio;
  }

  get formPortofolio() {
    return this.portofolio;
  }

  @Mutation
  [Mutations.listPortofolio](resData) {
    console.log(resData);
    this.listArrayPortofolio = resData;
  }

  @Mutation
  [Mutations.setFormPortofolio](resData) {
    console.log(resData);
    this.portofolio = resData;
  }

  @Action
  [Actions.postPortofolio](rowData) {
    ApiService.setHeader();
    return ApiService.post("portofolio", rowData)
      .then(({ data }) => {
        console.log(data);
        this.context.commit(Mutations.statusResApi, true);
      })
      .catch(({ response }) => {
        // this.context.commit(Mutations.SET_AUTH, []);
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.putPortofolio](rowData) {
    ApiService.setHeader();
    return ApiService.put("portofolio/" + rowData.id, rowData)
      .then(({ data }) => {
        console.log(data);
        this.context.commit(Mutations.statusResApi, true);
      })
      .catch(({ response }) => {
        // this.context.commit(Mutations.SET_AUTH, []);
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.deletePortofolio](rowData) {
    ApiService.setHeader();
    return ApiService.delete("portofolio/" + rowData.id)
      .then(({ data }) => {
        console.log(data);
        this.context.commit(Mutations.statusResApi, true);
      })
      .catch(({ response }) => {
        // this.context.commit(Mutations.SET_AUTH, []);
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.getPortofolio]() {
    return ApiService.get("portofolio")
      .then(({ data }) => {
        // this.context.commit(Mutations.statusResApi, true);
        this.context.commit(Mutations.listPortofolio, data);
      })
      .catch(({ response }) => {
        // this.context.commit(Mutations.SET_AUTH, []);
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
}
