import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    meta: {
      middleware: "auth",
    },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        meta: {
          pageTitle: "Dashboard",
          breadcrumbs: ["Dashboards"],
        },
      },
      {
        path: "/kelolaartikel",
        name: "kelolaartikel",
        component: () => import("@/views/artikel/kelolaArtikel.vue"),
        meta: {
          pageTitle: "Artikel",
        },
      },
      {
        path: "/kelolaclient",
        name: "kelolaclient",
        component: () => import("@/views/client/kelolaClient.vue"),
        meta: {
          pageTitle: "Client",
        },
      },
      {
        path: "/kelolakontak",
        name: "kelolakontak",
        component: () => import("@/views/kontak/kelolaKontak.vue"),
        meta: {
          pageTitle: "Kontak",
        },
      },
      {
        path: "/kelolaportofolio",
        name: "kelolaportofolio",
        component: () => import("@/views/portofolio/kelolaPortofolio.vue"),
        meta: {
          pageTitle: "Portofolio",
        },
      },
      {
        path: "/kelolalayanan",
        name: "kelolalayanan",
        component: () => import("@/views/layanan/kelolaLayanan.vue"),
        meta: {
          pageTitle: "Layanan",
        },
      },
      {
        path: "/kelolagaleri",
        name: "kelolagaleri",
        component: () => import("@/views/galeri/kelolaGaleri.vue"),
        meta: {
          pageTitle: "Galeri",
        },
      },
      {
        path: "/kelolamenu",
        name: "kelolamenu",
        component: () => import("@/views/menu/kelolaMenu.vue"),
        meta: {
          pageTitle: "Menu",
        },
      },
      {
        path: "/kelolaprofil",
        name: "kelolaprofil",
        component: () => import("@/views/profil/kelolaProfil.vue"),
        meta: {
          pageTitle: "Profil",
        },
      },
      {
        path: "/kelolaslider",
        name: "kelolaslider",
        component: () => import("@/views/slider/kelolaSlider.vue"),
        meta: {
          pageTitle: "Slider",
        },
      },
      {
        path: "/kelolauser",
        name: "kelolauser",
        component: () => import("@/views/user/kelolaUser.vue"),
        meta: {
          pageTitle: "User",
        },
      },
      {
        path: "/kelolarole",
        name: "kelolarole",
        component: () => import("@/views/role/kelolaRole.vue"),
        meta: {
          pageTitle: "User",
        },
      },
      {
        path: "/detailviewquotation",
        name: "detail-detailviewquotation",
        component: () =>
          import("@/views/rekapQuotation/detailViewQuotation.vue"),
        meta: {
          pageTitle: "Detail Quotation",
        },
      },
      {
        path: "/builder",
        name: "builder",
        component: () => import("@/views/LayoutBuilder.vue"),
        meta: {
          pageTitle: "Layout Builder",
          breadcrumbs: ["Layout"],
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
        meta: {
          pageTitle: "Sign In",
        },
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
        meta: {
          pageTitle: "Sign Up",
        },
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
        meta: {
          pageTitle: "Password reset",
        },
      },
    ],
  },
  {
    path: "/multi-step-sign-up",
    name: "multi-step-sign-up",
    component: () =>
      import("@/views/crafted/authentication/MultiStepSignUp.vue"),
    meta: {
      pageTitle: "Multi-step Sign up",
    },
  },
  {
    path: "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    children: [
      {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/crafted/authentication/Error404.vue"),
        meta: {
          pageTitle: "Error 404",
        },
      },
      {
        path: "/500",
        name: "500",
        component: () => import("@/views/crafted/authentication/Error500.vue"),
        meta: {
          pageTitle: "Error 500",
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // current page view title
  document.title = `${to.meta.pageTitle} - ${process.env.VUE_APP_NAME}`;

  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  // verify auth token before each page change
  store.dispatch(Actions.VERIFY_AUTH);

  // before page access check if page requires authentication
  if (to.meta.middleware == "auth") {
    if (store.getters.isUserAuthenticated) {
      next();
    } else {
      next({ name: "sign-in" });
    }
  } else {
    next();
  }

  // Scroll page to top on every route change
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
});

export default router;
