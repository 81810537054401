import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Client {
  id: number;
  client: string;
  gambar: number;
  Status: number;
  displayImage: string;
}

export interface ClientInfo {
  client: Client;
  listArrayClient: Array<Client>;
}

@Module
export default class ClientModule extends VuexModule implements ClientInfo {
  client = {} as Client;
  listArrayClient = [];

  get resGetClient() {
    return this.listArrayClient;
  }

  get formClient() {
    return this.client;
  }

  @Mutation
  [Mutations.listClient](resData) {
    console.log(resData);
    this.listArrayClient = resData;
  }

  @Mutation
  [Mutations.setFormClient](resData) {
    console.log(resData);
    this.client = resData;
  }

  @Action
  [Actions.postClient](rowData) {
    ApiService.setHeader();
    return ApiService.post("client/", rowData)
      .then(({ data }) => {
        console.log(data);
        this.context.commit(Mutations.statusResApi, true);
      })
      .catch(({ response }) => {
        // this.context.commit(Mutations.SET_AUTH, []);
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.putClient](rowData) {
    ApiService.setHeader();
    rowData.displayImage = "";
    return ApiService.put("client/" + rowData.id, rowData)
      .then(({ data }) => {
        console.log(data);
        this.context.commit(Mutations.statusResApi, true);
      })
      .catch(({ response }) => {
        // this.context.commit(Mutations.SET_AUTH, []);
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.deleteClient](rowData) {
    ApiService.setHeader();
    return ApiService.delete("client/" + rowData.id)
      .then(({ data }) => {
        console.log(data);
        this.context.commit(Mutations.statusResApi, true);
      })
      .catch(({ response }) => {
        // this.context.commit(Mutations.SET_AUTH, []);
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.getClient]() {
    return ApiService.get("client/")
      .then(({ data }) => {
        console.log(data);

        // this.context.commit(Mutations.statusResApi, true);
        this.context.commit(Mutations.listClient, data);
      })
      .catch(({ response }) => {
        // this.context.commit(Mutations.SET_AUTH, []);
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
}
