import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import ConfigModule from "@/store/modules/ConfigModule";
import ThemeModeModule from "@/store/modules/ThemeModeModule";
import ArtikelModule from "@/store/modules/ArtikelModule";
import ModalsModule from "@/store/modules/ModalsForm";
import KontakModule from "@/store/modules/KontakModule";
import PortofolioModule from "@/store/modules/PortofolioModule";
import ClientModule from "@/store/modules/ClientModule";
import LayananModule from "@/store/modules/LayananModule";
import MenuModule from "@/store/modules/MenuModule";
import SliderModule from "@/store/modules/SliderModule";
import GalerModule from "@/store/modules/GaleriModule";
import UserModule from "@/store/modules/UsersModule";
import DokumenModule from "@/store/modules/DokumenModule";
import ProfilModule from "@/store/modules/ProfilModule";
import RoleModule from "@/store/modules/RoleModule";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    ConfigModule,
    ThemeModeModule,
    ArtikelModule,
    ModalsModule,
    KontakModule,
    PortofolioModule,
    ClientModule,
    LayananModule,
    MenuModule,
    SliderModule,
    GalerModule,
    UserModule,
    DokumenModule,
    ProfilModule,
    RoleModule,
  },
});

export default store;
