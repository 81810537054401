enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  SET_THEME_MODE_ACTION = "setThemeModeAction",

  // action artikel
  postArtikel = "postArtikel",
  getArtikel = "getArtikel",
  putArtikel = "putArtikel",
  deleteArtikel = "deleteArtikel",

  // action kontak
  putKontak = "putKontak",
  getKontak = "getKontak",

  // action kontak
  putProfil = "putProfil",
  getProfil = "getProfil",

  // action portofolio
  getPortofolio = "getPortofolio",
  postPortofolio = "postPortofolio",
  putPortofolio = "putPortofolio",
  deletePortofolio = "deletePortofolio",

  // action client
  getClient = "getClient",
  postClient = "postClient",
  putClient = "putClient",
  deleteClient = "deleteClient",

  // action Layanan
  getLayanan = "getLayanan",
  postLayanan = "postLayanan",
  putLayanan = "putLayanan",
  deleteLayanan = "deleteLayanan",

  // action Menu
  getMenu = "getMenu",
  postMenu = "postMenu",
  putMenu = "putMenu",
  deleteMenu = "deleteMenu",

  // action Slider
  getSlider = "getSlider",
  postSlider = "postSlider",
  putSlider = "putSlider",
  deleteSlider = "deleteSlider",

  // action Galeri
  getGaleri = "getGaleri",
  postGaleri = "postGaleri",
  putGaleri = "putGaleri",
  deleteGaleri = "deleteGaleri",
  getGaleriById = "getGaleriById",
  ambilGambarById = "ambilGambarById",

  // action User
  getUser = "getUser",
  postUser = "postUser",
  putUser = "putUser",
  deleteUser = "deleteUser",

  // role
  getRole = "getRole",
  postRole = "postRole",
  putRole = "putRole",
  deleteRole = "deleteRole",

  // dokumen
  getDokumen = "getDokumen",
  getDokumenById = "getDokumenById",
  postDokumen = "postDokumen",
  putDokumen = "putDokumen",
  deleteDokumen = "deleteDokumen",
  downloadDokumen = "downloadDokumen",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_EditProfil = "SET_EditProfil",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG_PROPERTY = "setLayoutConfigProperty",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_THEME_MODE_MUTATION = "setThemeModeMutation",

  // mutation artikel
  setFormArtikel = "setFormArtikel",
  listArtikel = "listArtikel",

  // mutation portofolio
  setFormPortofolio = "setFormPortofolio",
  listPortofolio = "listPortofolio",

  // mutation Client
  setFormClient = "setFormClient",
  listClient = "listClient",

  // mutation Layanan
  setFormLayanan = "setFormLayanan",
  listLayanan = "listLayanan",

  // mutation Menu
  setFormMenu = "setFormMenu",
  listMenu = "listMenu",

  // mutation Slider
  setFormSlider = "setFormSlider",
  listSlider = "listSlider",

  // mutation Galeri
  setFormGaleri = "setFormGaleri",
  listGaleri = "listGaleri",

  // mutation Dokumen
  setFormDokumen = "setFormDokumen",
  listDokumen = "listDokumen",
  setIdDokumen = "setIdDokumen",

  // mutation User
  setFormUser = "setFormUser",
  listUser = "listUser",

  // mutation role
  listRole = "listRole",
  setFormRole = "setFormRole",

  // mutation kontak
  setFormKontak = "setFormKontak",
  listKontak = "listKontak",

  // mutation profil
  setFormProfil = "setFormProfil",
  listProfil = "listProfil",

  // status response
  statusResApi = "statusResApi",

  // file binnary
  fileBinary = "fileBinary",
  setBase64 = "setBase64",

  // modalForm
  modalsForm = "modalsForm",
  modalsFormTambah = "modalsFormTambah",
  modalsFormEdit = "modalsFormEdit",
  modalFormEditProfil = "modalFormEditProfil",
  modalsFormDelete = "modalsFormDelete",
}

export { Actions, Mutations };
