import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Kontak {
  id: number;
  headoffice: string;
  workshop: string;
  peta: string;
  email: string;
  telp: string;
  twitter: string;
  instagram: string;
  facebook: string;
}

export interface KontakInfo {
  jsonKontak: Kontak;
  listArrayKontak: Array<Kontak>;
}

@Module
export default class KontakModule extends VuexModule implements KontakInfo {
  jsonKontak = {} as Kontak;
  listArrayKontak = [];

  get resGetKontak() {
    return this.listArrayKontak;
  }

  get formKontak() {
    return this.jsonKontak;
  }

  @Mutation
  [Mutations.listKontak](resData) {
    console.log(resData);
    this.listArrayKontak = resData;
  }

  @Mutation
  [Mutations.setFormKontak](resData) {
    console.log(resData);
    this.jsonKontak = resData;
  }

  @Action
  [Actions.putKontak](rowData) {
    ApiService.setHeader();
    return ApiService.put("kontak", rowData)
      .then(({ data }) => {
        console.log(data);
        this.context.commit(Mutations.statusResApi, true);
      })
      .catch(({ response }) => {
        // this.context.commit(Mutations.SET_AUTH, []);
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.getKontak]() {
    return ApiService.get("kontak")
      .then(({ data }) => {
        console.log(data);
        // this.context.commit(Mutations.statusResApi, true);
        this.context.commit(Mutations.listKontak, [data]);
      })
      .catch(({ response }) => {
        // this.context.commit(Mutations.SET_AUTH, []);
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
}
