import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Slider {
  id: number;
  judul: string;
  isi: string;
  url: string;
  gambar: number;
  urutan: number;
  status: number;
  displayImage: string;
}

export interface SliderInfo {
  objectSlider: Slider;
  listArraySlider: Array<Slider>;
}

@Module
export default class SliderModule extends VuexModule implements SliderInfo {
  objectSlider = {} as Slider;
  listArraySlider = [];

  get resGetSlider() {
    return this.listArraySlider;
  }

  get formSlider() {
    return this.objectSlider;
  }

  @Mutation
  [Mutations.listSlider](resData) {
    console.log(resData);
    this.listArraySlider = resData;
  }

  @Mutation
  [Mutations.setFormSlider](resData) {
    console.log(resData);
    this.objectSlider = resData;
  }

  @Action
  [Actions.postSlider](rowData) {
    ApiService.setHeader();
    return ApiService.post("slider/", rowData)
      .then(({ data }) => {
        console.log(data);
        this.context.commit(Mutations.statusResApi, true);
      })
      .catch(({ response }) => {
        // this.context.commit(Mutations.SET_AUTH, []);
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.putSlider](rowData) {
    ApiService.setHeader();
    return ApiService.put("slider/" + rowData.id, rowData)
      .then(({ data }) => {
        console.log(data);
        this.context.commit(Mutations.statusResApi, true);
      })
      .catch(({ response }) => {
        // this.context.commit(Mutations.SET_AUTH, []);
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.deleteSlider](rowData) {
    ApiService.setHeader();
    return ApiService.delete("slider/" + rowData.id)
      .then(({ data }) => {
        console.log(data);
        this.context.commit(Mutations.statusResApi, true);
      })
      .catch(({ response }) => {
        // this.context.commit(Mutations.SET_AUTH, []);
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.getSlider]() {
    return ApiService.get("slider/")
      .then(({ data }) => {
        // this.context.commit(Mutations.statusResApi, true);
        this.context.commit(Mutations.listSlider, data);
      })
      .catch(({ response }) => {
        // this.context.commit(Mutations.SET_AUTH, []);
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
}
