import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Roles {
  id: number;
  name: string;
}

export interface User {
  id: number;
  foto: string;
  username: string;
  email: string;
  password: string;
  name: string;
  roles: Array<Roles>;
  displayImage: string;
}

export interface UserInfo {
  objectUser: User;
  listArrayUser: Array<User>;
}

@Module
export default class UserModule extends VuexModule implements UserInfo {
  objectUser = {} as User;
  listArrayUser = [];

  get resGetUser() {
    return this.listArrayUser;
  }

  get formUser() {
    return this.objectUser;
  }

  @Mutation
  [Mutations.listUser](resData) {
    console.log(resData);
    this.listArrayUser = resData;
  }

  @Mutation
  [Mutations.setFormUser](resData) {
    console.log(resData);
    this.objectUser = resData;
  }

  @Action
  [Actions.postUser](rowData) {
    // ApiService.setHeader();
    return ApiService.post("auth/signup", rowData)
      .then(({ data }) => {
        console.log(data);
        this.context.commit(Mutations.statusResApi, true);
      })
      .catch(({ response }) => {
        // this.context.commit(Mutations.SET_AUTH, []);
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.putUser](rowData) {
    ApiService.setHeader();
    const formdata = new FormData();
    formdata.append("username", rowData.username);
    formdata.append("email", rowData.email);
    formdata.append("password", rowData.password);
    formdata.append("foto", rowData.foto);
    formdata.append("name", rowData.name);
    formdata.append("roles", rowData.roles);
    return ApiService.putFormData("user/" + rowData.id, formdata)
      .then(({ data }) => {
        console.log(data);
        this.context.commit(Mutations.statusResApi, true);
      })
      .catch(({ response }) => {
        // this.context.commit(Mutations.SET_AUTH, []);
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.deleteUser](rowData) {
    ApiService.setHeader();
    return ApiService.delete("user/hapus/" + rowData.id)
      .then(({ data }) => {
        console.log(data);
        this.context.commit(Mutations.statusResApi, true);
      })
      .catch(({ response }) => {
        // this.context.commit(Mutations.SET_AUTH, []);
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.getUser]() {
    return ApiService.get("user/all")
      .then(({ data }) => {
        // this.context.commit(Mutations.statusResApi, true);
        this.context.commit(Mutations.listUser, data);
      })
      .catch(({ response }) => {
        // this.context.commit(Mutations.SET_AUTH, []);
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
}
