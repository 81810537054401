import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Galeri {
  id: number;
  judul: string;
  kategori: number;
  url: string;
  gambar: string;
  tipe: number;
  frame: string;
}

export interface GaleriInfo {
  objectGaleri: Galeri;
  listArrayGaleri: Array<Galeri>;
  gbrBase64: string;
}

@Module
export default class GaleriModule extends VuexModule implements GaleriInfo {
  objectGaleri = {} as Galeri;
  listArrayGaleri = [];
  objectFileBinary = {};
  gbrBase64 = "";

  get resGetBase64() {
    return this.gbrBase64;
  }

  get resGetGaleri() {
    return this.listArrayGaleri;
  }

  get formGaleri() {
    return this.objectGaleri;
  }

  get resFileBinary() {
    return this.objectFileBinary;
  }

  @Mutation
  [Mutations.setBase64](resData) {
    this.gbrBase64 = resData;
  }

  @Mutation
  [Mutations.listGaleri](resData) {
    console.log(resData);
    this.listArrayGaleri = resData.isidata;
  }

  @Mutation
  [Mutations.setFormGaleri](resData) {
    console.log(resData);
    this.objectGaleri = resData;
  }

  @Mutation
  [Mutations.fileBinary](resData) {
    console.log(resData);
    this.objectFileBinary = resData;
  }

  @Mutation
  [Mutations.setIdDokumen](resData) {
    console.log(resData);
    console.log(this.objectGaleri);
    this.objectGaleri = resData;
  }

  @Action
  [Actions.postGaleri](rowData) {
    ApiService.setHeader();
    const formdata = new FormData();
    formdata.append("gambar", rowData.gambar);
    formdata.append("url", rowData.url);
    formdata.append("judul", rowData.judul);
    formdata.append("tipe", rowData.tipe);
    formdata.append("kategori", rowData.kategori);
    formdata.append("frame", rowData.frame);
    formdata.append("file", rowData.file);
    return ApiService.postFormData("galleri/", formdata)
      .then(({ data }) => {
        this.context.commit(Mutations.setIdDokumen, data);
      })
      .catch(({ response }) => {
        // this.context.commit(Mutations.SET_AUTH, []);
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.putGaleri](rowData) {
    ApiService.setHeader();
    const formdata = new FormData();
    formdata.append("gambar", rowData.gambar);
    formdata.append("url", rowData.url);
    formdata.append("tipe", rowData.tipe);
    formdata.append("judul", rowData.judul);
    formdata.append("kategori", rowData.kategori);
    formdata.append("file", rowData.file);
    formdata.append("frame", rowData.frame);
    console.log(rowData);
    console.log("cek1");
    return ApiService.putFormData("galleri/" + rowData.id, formdata)
      .then(({ data }) => {
        console.log(data);
        this.context.commit(Mutations.setIdDokumen, rowData);
      })
      .catch(({ response }) => {
        // this.context.commit(Mutations.SET_AUTH, []);
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.deleteGaleri](rowData) {
    ApiService.setHeader();
    return ApiService.delete("galleri/" + rowData.id)
      .then(({ data }) => {
        console.log(data);
        this.context.commit(Mutations.statusResApi, true);
      })
      .catch(({ response }) => {
        // this.context.commit(Mutations.SET_AUTH, []);
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.getGaleri](rowData) {
    return ApiService.get("galleri/all/" + rowData.size + "/" + rowData.page)
      .then(({ data }) => {
        // this.context.commit(Mutations.statusResApi, true);
        this.context.commit(Mutations.listGaleri, data);
      })
      .catch(({ response }) => {
        // this.context.commit(Mutations.SET_AUTH, []);
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.getGaleriById](id) {
    return ApiService.get("galleri/byid/" + id)
      .then(({ data }) => {
        // this.context.commit(Mutations.statusResApi, true);
        this.context.commit(Mutations.setFormGaleri, data);
      })
      .catch(({ response }) => {
        // this.context.commit(Mutations.SET_AUTH, []);
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.ambilGambarById](name) {
    return ApiService.get("galleri/ambilgbr/" + name)
      .then(({ data }) => {
        // this.context.commit(Mutations.statusResApi, true);
        this.context.commit(Mutations.setBase64, data);
      })
      .catch(({ response }) => {
        // this.context.commit(Mutations.SET_AUTH, []);
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
}
