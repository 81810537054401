import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Artikel {
  id: number;
  judul: string;
  subjudul: string;
  gambar: number;
  isi: string;
  kategori: string;
  Status: number;
  displayImage: string;
}

export interface ArtikelInfo {
  artikel: Artikel;
  listArrayArtikel: Array<Artikel>;
}

@Module
export default class ArtikelModule extends VuexModule implements ArtikelInfo {
  artikel = {} as Artikel;
  listArrayArtikel = [];

  get resGetArtikel() {
    return this.listArrayArtikel;
  }

  get formArtikel() {
    return this.artikel;
  }

  @Mutation
  [Mutations.listArtikel](resData) {
    console.log(resData);
    this.listArrayArtikel = resData.isidata;
  }

  @Mutation
  [Mutations.setFormArtikel](resData) {
    console.log(resData);
    this.artikel = resData;
  }

  @Action
  [Actions.postArtikel](rowData) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      const formdata = new FormData();
      formdata.append("judul", rowData.judul);
      formdata.append("subjudul", rowData.subjudul);
      formdata.append("isi", rowData.isi);
      formdata.append("kategori", rowData.kategori);
      formdata.append("status", rowData.status);
      formdata.append("gambar", rowData.gambar);
      return ApiService.post("artikel", rowData)
        .then(({ data }) => {
          console.log(data);
          this.context.commit(Mutations.statusResApi, true);
          resolve(data);
        })
        .catch(({ response }) => {
          // this.context.commit(Mutations.SET_AUTH, []);
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  }

  @Action
  [Actions.putArtikel](rowData) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      rowData.displayImage = "";
      return ApiService.put("artikel/" + rowData.id, rowData)
        .then(({ data }) => {
          console.log(data);
          this.context.commit(Mutations.statusResApi, true);
          resolve(data);
        })
        .catch(({ response }) => {
          // this.context.commit(Mutations.SET_AUTH, []);
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  }

  @Action
  [Actions.deleteArtikel](rowData) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      return ApiService.delete("artikel/" + rowData.id)
        .then(({ data }) => {
          console.log(data);
          this.context.commit(Mutations.statusResApi, true);
          resolve(data);
        })
        .catch(({ response }) => {
          // this.context.commit(Mutations.SET_AUTH, []);
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  }

  @Action
  [Actions.getArtikel](rowData) {
    return new Promise((resolve, reject) => {
      return ApiService.get("artikel/all/" + rowData.size + "/" + rowData.page)
        .then(({ data }) => {
          // this.context.commit(Mutations.statusResApi, true);
          this.context.commit(Mutations.listArtikel, data);
          resolve(data);
        })
        .catch(({ response }) => {
          // this.context.commit(Mutations.SET_AUTH, []);
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  }
}
