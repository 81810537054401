import { Mutations } from "@/store/enums/StoreEnums";
import { Module, Mutation, VuexModule } from "vuex-module-decorators";

export interface ComponentModal {
  tambah: boolean;
  edit: boolean;
  delete: boolean;
  editProfil: boolean;
}

export interface ModalsInfo {
  componentModal: ComponentModal;
}

@Module
export default class ModalsModule extends VuexModule implements ModalsInfo {
  componentModal = {} as ComponentModal;

  get getModals() {
    return this.componentModal;
  }

  @Mutation
  [Mutations.modalsForm](modalValue) {
    this.componentModal = modalValue;
  }

  @Mutation
  [Mutations.modalsFormTambah](modalValue) {
    this.componentModal.tambah = modalValue;
  }

  @Mutation
  [Mutations.modalsFormEdit](modalValue) {
    this.componentModal.edit = modalValue;
  }

  @Mutation
  [Mutations.modalFormEditProfil](modalValue) {
    this.componentModal.editProfil = modalValue;
  }

  @Mutation
  [Mutations.modalsFormDelete](modalValue) {
    this.componentModal.delete = modalValue;
  }
}
