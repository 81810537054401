import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Profil {
  id: number;
  sambutan: string;
  profil: string;
  strukturorganisasi: number;
}

export interface ProfilInfo {
  jsonProfil: Profil;
  listArrayProfil: Array<Profil>;
}

@Module
export default class ProfilModule extends VuexModule implements ProfilInfo {
  jsonProfil = {} as Profil;
  listArrayProfil = [];

  get resGetProfil() {
    return this.listArrayProfil;
  }

  get formProfil() {
    return this.jsonProfil;
  }

  @Mutation
  [Mutations.listProfil](resData) {
    console.log(resData);
    this.listArrayProfil = resData;
  }

  @Mutation
  [Mutations.setFormProfil](resData) {
    console.log(resData);
    this.jsonProfil = resData;
  }

  @Action
  [Actions.putProfil](rowData) {
    ApiService.setHeader();
    return ApiService.put("profil", rowData)
      .then(({ data }) => {
        console.log(data);
        this.context.commit(Mutations.statusResApi, true);
      })
      .catch(({ response }) => {
        // this.context.commit(Mutations.SET_AUTH, []);
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.getProfil]() {
    return ApiService.get("profil/getbyid")
      .then(({ data }) => {
        console.log(data);
        // this.context.commit(Mutations.statusResApi, true);
        this.context.commit(Mutations.listProfil, [data]);
      })
      .catch(({ response }) => {
        // this.context.commit(Mutations.SET_AUTH, []);
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
}
